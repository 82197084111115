<template>
  <div>
    <b-row class="squareGrid">
      <b-col lg="6" md="12" clos="12" class="squareColLeft">
        <!-- <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Fji7FhQDVsE?autoplay=1&mute=1" allow="autoplay *; fullscreen *; loop *;" allowfullscreen="true"></iframe> -->
        <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/Fji7FhQDVsE?loop=1&autoplay=1&mute=1" allowfullscreen></iframe> -->
        <!-- <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Fji7FhQDVsE?playlist=Fji7FhQDVsE&loop=1&rel=0&autoplay=1&mute=1&controls=0&showinfo=0" frameborder="0" allowfullscreen></iframe> -->
      </b-col>
      <b-col id="blueGrad" lg="6" md="12" cols="12" class="squareCol">
        <div class="container">
          <div class="gradText">
            <h3 class="gridTitle" style="color:white;">
              {{ data.blue_title }}
            </h3>
            <br />
            <p>
              <span v-html="data.blue_text"></span>
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row class="squareGrid" style="display:none">
      <b-col
        lg="6"
        md="12"
        cols="12"
        order="2"
        order-lg="2"
        order-md="1"
        class="squareColRight"
      ></b-col>
      <b-col
        id="redGrad"
        lg="6"
        md="12"
        cols="12"
        order="1"
        order-lg="1"
        order-md="2"
      >
        <div class="container">
          <div class="gradText">
            <h3 class="gridTitle" style="color:white;">{{ data.red_title }}</h3>
            <br />
            <span v-html="data.red_text"></span>
            <br />
            <div id="appsStore">
              <a :href="config.VUE_APP_APPLE_STORE">
                <img
                  class="app"
                  src="https://assets.asiantour.com/toyota-tour/2024/02/Mask-Group-5.png"
                />
              </a>
              <a :href="config.VUE_APP_GOOGLE_PLAY">
                <img
                  class="app"
                  src="https://assets.asiantour.com/toyota-tour/2024/02/Mask-Group-4.png"
                />
              </a>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import apiCall from "@/services/api.js";
export default {
  name: "cubes",
  props: ["config"],
  data() {
    return {
      data: [],
    };
  },
  created() {
    const json = "wp/v2/pages/8";
    apiCall
      .wpInfo(json)
      .then(({ data }) => {
        this.data = data.acf;
        this.loading = false;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};
</script>

<style scoped>
iframe {
  border: none;
}
img.app {
  padding-right: 15px;
  width: 200px !important;
}
#homeButton:hover {
  background-color: #EB0A1E;
  color: white;
  border-color: #EB0A1E;
  border-style: solid;
  border-width: thin;
  padding: 10px 50px 10px 50px;
}
.squareColLeft {
  background-image: url(https://assets.asiantour.com/toyota-tour/2025/03/tun.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-right: 0px;
}
#blueGrad {
  background-color: #3E3E3E;
  color: white;
}
#redGrad {
  background-color: #EB0A1E;
  color: white;
}
.squareColRight {
  background-image: url(https://assets.asiantour.com/toyota-tour/2024/02/img-1a.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
.gridTitle {
  font-size: calc(1.2vw + 1.7vh);
}
.GridSmall {
  font-weight: 100;
}
.gradText {
  padding-top: calc(3vw + 3vh);
  padding-left: calc(3vw + 3vh);
  padding-right: calc(3vw + 3vh);
  padding-bottom: calc(3vw + 3vh);
}
#homeButton {
  background-color: transparent;
  color: white;
  border-color: white;
  border-style: solid;
  border-width: thin;
  padding: 10px 50px 10px 50px;
}
@media only screen and (max-width: 990px) {
  .squareGrid {
    display: block;
  }
  .squareColLeft {
    height: 800px;
  }
  .squareColRight {
    height: 800px;
  }
}
@media only screen and (max-width: 480px) {
  .squareColLeft {
    height: 500px;
  }
  .squareColRight {
    height: 500px;
  }
}
</style>
